.single {
    .content {

        > h2,
        > h3,
        > h4,
        > h5,
        > h6 {
            > .header-mark::before {
                content: inherit;
            }
        }

        p {
            margin: 1.5rem 0;
            clear: both;
        }

        figure {
            margin: 0;
        }
    }

    .single-title {
        margin-bottom: 1.5rem;
    }
}

footer {
    margin-top: 2rem;
}

header {
    .logo {
        min-height: 1em;
        height: 1em;
    }
}

.image-left {
    width: 45%;
    float: left;
}

.image-right {
    width: 45%;
    float: right;
}
