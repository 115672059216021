$primary-color: #235547;
$secondary-color: #237e47;
$tertiary-color: #e56c1a;

// Color of the link
// $global-link-color: $secondary-color;
$global-link-hover-color: $tertiary-color;

// Color of the single link
$single-link-color: $secondary-color;
$single-link-hover-color: $tertiary-color;

// Color of the link in pagination
$pagination-link-color: $primary-color;
$pagination-link-hover-color: $tertiary-color;

$global-font-size: 17px;
